<template>
  <basic-container>
    <div class="mar-t-20">
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="140px"
        size="small"
        label-position="right"
        :rules="ruleModel"
      >
        <el-row>
          <el-col :span="20">
            <el-form-item label="门禁名称" prop="name">
              <el-input
                v-model="ruleForm.name"
                placeholder="请输入门禁名称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="门禁类型" prop="doorType">
              <el-radio-group v-model="ruleForm.doorType">
                <!-- <el-radio :label="0">人员通道门禁</el-radio>
                <el-radio :label="1">会议室门禁</el-radio> -->
                <el-radio
                  v-for="item in doorTypes"
                  :key="item.value"
                  :label="item.value"
                  >{{ item.name }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="ruleForm.doorType == 0">
          <el-col :span="20">
            <el-form-item label="所在楼宇" prop="locationBuildId">
              <el-select
                @change="changeBuild"
                v-model="ruleForm.locationBuildId"
                size="small"
                placeholder="请选择门禁所在楼宇"
              >
                <el-option
                  v-for="item in buildList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="20">
            <el-form-item label="所在楼层" prop="locationFloorId">
              <el-select
                @change="changeFloor"
                v-model="ruleForm.locationFloorId"
                size="small"
                placeholder="请选择门禁所在楼层"
              >
                <el-option
                  v-for="item in floors"
                  :key="item.id"
                  :label="item.code"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="20">
            <el-form-item label="所在方位" prop="locationName">
              <el-input
                placeholder="请输入门禁所在方位"
                v-model="ruleForm.locationName"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="20">
            <el-form-item label="安装顺序(从右到左)" prop="doorIndex">
              <el-input
                placeholder="请输入门禁所在顺序"
                v-model="ruleForm.doorIndex"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="ruleForm.doorType == 1">
          <el-col :span="20">
            <el-form-item label="所在会议室" prop="building_id">
              <el-select
                @change="changeCodRoom"
                v-model="ruleForm.locationCodRoomId"
                size="small"
                placeholder="请选择门禁所在会议室"
              >
                <el-option
                  v-for="item in codroomList"
                  :key="item.id"
                  :label="item.fullName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="ruleForm.doorType == 2">
          <el-col :span="20">
            <el-form-item label="所在场地" prop="locationCodRoomId">
              <el-select
                v-model="ruleForm.locationCodRoomId"
                size="small"
                placeholder="请选择门禁所在场地"
              >
                <el-option
                  v-for="item in SporPages"
                  :key="item.id"
                  :label="item.fullName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="btnClass bor-t">
        <el-button
          size="small"
          class="mar-t-10 mar-r-10 pull-right"
          type=""
          @click="close"
          >取消</el-button
        >
        <el-button
          size="small"
          class="mar-t-10 mar-r-5 pull-right"
          type="primary"
          :loading="saveLoading"
          @click="save"
          >保存</el-button
        >
      </div>
    </div>
  </basic-container>
</template>

<script>
import * as dictbizService from "@/api/system/dictbiz";

import standardService from "@/api/metadata/standard";
import { mapGetters, mapState } from "vuex";

import {
  getMetaCommonInfo,
  saveMetaCommon,
  updateMetaCommon,
  getPicListByIds,
} from "@/api/busiMode/metaCommon";
import { getMetaCommonList, delMetaCommon } from "@/api/busiMode/metaCommon";
import { getPage } from "@/api/spcod/sportstadium";
import { getSportgroundPage } from "@/api/spcod/codroom";

// import { getSportgroundPage } from "@/api/spcod/sportground";

export default {
  data() {
    return {
      ruleModel: {},
      ruleForm: { doorType: 0 },
      buildList: [],
      saveLoading: false,
      entModel: "wisdom_park.wp_third_hk_resources_doors",
      buildModel: "wisdom_park.wp_building",
      codroomList: [],
      floors: [],
      doorTypes: [],
      SporPages: [],
    };
  },
  computed: {},
  mounted() {
    // this.queryByModelCode();
    this.getMetaCommonList();
    this.getCodroom();
    this.getDicList();
    this.getSporList();
  },
  computed: {},
  props: {
    currentRow: {
      type: Object,
      default: {},
    },
    type: {
      type: String,
      default: "detail",
    },
  },
  watch: {
    currentRow: {
      immediate: true,
      handler(val) {
        if (val.indexCode) {
          console.log("打印打印");
          this.getMetaCommonInfo();
        }
      },
    },
    "ruleForm.locationBuildId": {
      handler: function (newValue, oldName) {
        if (newValue) {
          this.getFloors();
        }
      },
      deep: true,
    },
    type: {
      immediate: true,
      handler(val) {},
    },
  },
  methods: {
    getDicList() {
      dictbizService
        .getDictionary({
          code: "DoorType",
        })
        .then((res) => {
          this.doorTypes = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: Number(item["dictKey"]),
            };
          });
        });
    },
    getCodroom() {
      this.loading = true;
      getSportgroundPage(1, 100).then((res) => {
        const data = res.data.data;
        console.log(this.codroomList);
        this.codroomList = data.records;
      });
    },
    getSporList() {
      getPage(1, 100).then((res) => {
        const data = res.data.data;
        this.SporPages = data.records;
        console.log(this.SporPages);
      });
    },

    getFloors() {
      let obj = {
        size: 1000,
        current: 1,
        orders: [{ field: "code+1000", sort: "DESC" }],
      };
      obj.wheres = [
        { field: "building_id", value: this.ruleForm.locationBuildId },
      ];
      getMetaCommonList("wisdom_park.wp_floor", obj).then((res) => {
        const data = res.data.data;
        this.floors = data.records;
      });
    },
    changeBuild() {
      let name = "";
      this.buildList.forEach((b) => {
        if (b.id == this.ruleForm.locationBuildId) {
          console.log(b);
          name = b.name;
        }
      });
      this.getFloors();
      this.ruleForm.locationBuildName = name;
    },
    changeFloor() {
      let name = "";
      this.floors.forEach((b) => {
        if (b.id == this.ruleForm.locationFloorId) {
          name = b.code;
        }
      });
      this.ruleForm.locationFloorName = name;
    },
    changeCodRoom() {
      let name = "";
      this.codroomList.forEach((b) => {
        if (b.id == this.ruleForm.locationCodRoomId) {
          this.ruleForm.locationBuildId = b.buildingId;

          this.ruleForm.locationBuildName = b.buildingName;
          this.ruleForm.locationFloorId = b.floorId;
          // this.ruleForm.locationBuildName=b.buildingName
          name = b.name;
        }
      });
      let obj = {
        size: 1000,
        current: 1,
      };
      obj.wheres = [
        { field: "building_id", value: this.ruleForm.locationBuildId },
      ];
      getMetaCommonList("wisdom_park.wp_floor", obj).then((res) => {
        const data = res.data.data;
        data.records.forEach((e) => {
          if (e.id == this.ruleForm.locationFloorId) {
            this.ruleForm.locationFloorName = e.code || e.name;
          }
        });
      });
      this.ruleForm.locationCodRoomName = name;
    },
    getMetaCommonInfo() {
      let obj = {
        pk: "indexCode",
        id: this.currentRow.indexCode,
      };
      getMetaCommonInfo(this.entModel, obj).then((res) => {
        if (res.data.code == 200) {
          if (!res.data.data.doorType) {
            res.data.data.doorType = 0;
          }
          this.ruleForm = res.data.data;
        }
      });
    },
    queryByModelCode() {
      standardService.getModelRule(this.buildModel).then((res) => {
        this.ruleModel = res;
      });
    },
    getMetaCommonList() {
      let b = {
        page: {
          size: 100,
          current: 1,
          total: 0,
        },
      };
      getMetaCommonList(this.buildModel, {
        size: 100,
        current: 1,
        total: 0,
      }).then((res) => {
        const data = res.data.data;
        this.buildList = data.records;
      });
    },
    submit() {
      return new Promise((resolve) => {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            resolve({
              valid: true,
              form: this.ruleForm,
            });
          } else {
            let fields = this.$refs["ruleForm"].fields,
              firstError = {
                field: "",
                message: "",
                name: "",
              };
            for (let i = 0; i < fields.length; i++) {
              if (fields[i].validateState === "error") {
                firstError.message = fields[i].validateMessage;
                firstError.field = fields[i].prop;
                firstError.name = fields[i].label;
                break;
              }
            }
            resolve({
              valid: false,
              form: this.ruleForm,
              error: firstError,
            });
          }
        });
      });
    },
    close() {
      this.$emit("close");
    },
    save() {
      // Promise.all().then((forms) => {
      this.saveLoading = true;

      if (this.ruleForm.doorType == 1) {
        this.ruleForm.locationName = "";
        this.changeCodRoom();
      }
      if (this.ruleForm.doorType == 0) {
        this.ruleForm.locationCodRoomName = "";
        delete this.ruleForm.locationCodRoomId;
        this.changeBuild();
        this.changeFloor();
      }
      if (this.ruleForm.doorType == 2) {
        this.SporPages.forEach((b) => {
          if (b.id == this.ruleForm.locationCodRoomId) {
            this.ruleForm.locationCodRoomName = b.fullName;
          }
        });
      }

      if (this.ruleForm.indexCode) {
        let o = {
          update: JSON.parse(JSON.stringify(this.ruleForm)),
          primaryKeyValue: this.ruleForm["indexCode"],
          primaryKey: "indexCode",
        };

        updateMetaCommon(this.entModel, o)
          .then((res) => {
            if (res.data.code == 200) {
              if (res.data.data.id) {
                this.$emit("close");
              } else {
                this.$emit("close");
              }
              this.saveLoading = false;
            }
          })
          .catch((e) => {
            this.saveLoading = false;
          });
      }
      // });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-divider--horizontal {
  margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
  left: 0px;
}
/deep/ .el-divider__text {
  padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
  width: 68px;
  height: 68px;
  line-height: 72px;
  vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 68px;
  height: 68px;
}
.btnClass {
  display: flow-root;
  height: 50px;
  line-height: 50px;
}
/deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
/deep/ .el-select {
  width: 100%;
}
</style>
